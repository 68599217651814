<template>
  <div class="main">
    <Head title="密码修改"></Head>
    <div class="el">
      <div class="Group-empty-list">
        <div class="Group-empty">
          <el-form
              ref="form"
              :model="editPasswordForm"
              :rules="editPasswordRules"
              class="form_width"
              label-width="180px"
              size="small"
          >
            <el-form-item prop="oldPassword" label="原密码：">
              <el-input
                  v-model="editPasswordForm.oldPassword"
                  clearable
                  placeholder="请输入原密码"
                  type= 'password'
                  class="phone"
                  tabindex="1"
              />
            </el-form-item>
            <el-form-item prop="newPassword" label="新密码：">
              <el-input
                  v-model="editPasswordForm.newPassword"
                  clearable
                  placeholder="请输入新密码"
                  type= 'password'
                  class="phone"
                  tabindex="2"
              />
            </el-form-item>
            <el-form-item prop="confirmPassword" label="确认新密码：">
              <el-input
                  v-model="editPasswordForm.confirmPassword"
                  clearable
                  placeholder="请输入新密码"
                  type= 'password'
                  class="phone"
                  tabindex="3"
              />
            </el-form-item>
            <div class="btn-wrap">
              <el-button
                  :loading="loading"
                  type="primary"
                  class="submit-btn"
                  @click.native.prevent="handleSubmit"
              >提交</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {passwordModify} from '@/api/user'
import md5 from 'js-md5'
import Head from './Head'
export default {
  name: 'Password',
  components: {
    Head
  },
  data() {
    const validateOldPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error('原密码不能为空！'))
      }/*else if (value.length < 6) {
        callback(new Error('密码不小于6位数！'))
      }*/ else {
        callback()
      }
    }
    // const validateNewPassword = (rule, value, callback) => {
    //   if (!value) {
    //     callback(new Error('新密码不能为空！'))
    //   }/*else if (value.length < 6) {
    //     callback(new Error('密码不小于6位数！'))
    //   }*/ else {
    //     callback()
    //   }
    // }
    const validateNewPassword = (rule, value, callback) => {
      // 验证长度
      if (value.length < 6 || value.length > 20) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位'));
        return;
      }

      // 验证是否包含字母
      if (!/[a-zA-Z]/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位'));
        return;
      }

      // 验证是否包含数字
      if (!/\d/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位'));
        return;
      }

      // 验证是否包含特殊字符
      // 这里使用的正则表达式匹配常见的特殊字符，您可以根据需要进行调整
      if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位'));
        return;
      }

      // 所有条件都满足
      callback();
    }

    const validateConfirmPassword = (rule, value, callback) => {
      // 验证长度
      if (value.length < 6 || value.length > 20) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位'));
        return;
      }

      // 验证是否包含字母
      if (!/[a-zA-Z]/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位'));
        return;
      }

      // 验证是否包含数字
      if (!/\d/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位'));
        return;
      }

      // 验证是否包含特殊字符
      // 这里使用的正则表达式匹配常见的特殊字符，您可以根据需要进行调整
      if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(value)) {
        callback(new Error('密码必须包含字母数字和特殊字符，且长度应在6至20位'));
        return;
      }

      // 所有条件都满足
      callback();
    }
    return {
      loading: false, // 按钮loading
      editPasswordForm: {

      },
      editPasswordRules: {
        oldPassword: [
          { required: true, trigger: 'blur', validator: validateOldPassword }
        ],
        newPassword: [
          { required: true, trigger: 'blur', validator: validateNewPassword }
        ],
        confirmPassword: [
          { required: true, trigger: 'blur', validator: validateConfirmPassword }
        ],
      }
    }
  },
  methods: {
    handleSubmit() {
      //校验两次新密码是否一致
      if(this.editPasswordForm.newPassword !== this.editPasswordForm.confirmPassword) {
        this.$message({
          type: 'error',
          message: '新密码也确认新密码不一致，请重新输入！',
          onClose: ()=>{
            this.editPasswordForm.newPassword = ''
            this.editPasswordForm.confirmPassword = ''

          }
        })
        return
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          console.log('ccc');
          const { newPassword, confirmPassword } = this.editPasswordForm
          const obj = {
            originPassword: md5(this.editPasswordForm.oldPassword),
            password: newPassword,
            confirmPassword: confirmPassword
          }
          passwordModify(obj).then(res=>{
            if(res.status === 200) {
              if(res.data.code === 0) {
                this.$store.dispatch('user/logout', obj).then( res =>{
                  console.log(res);
                  this.loading = false
                  if(res.status === 200) {
                    if(res.data.code === 0) {
                      this.$message({
                        type: 'success',
                        message: '修改成功，请重新登录！',
                        onClose: ()=>{
                          this.$router.push({ // 登录成功跳转至首页
                            path: '/'
                          })
                          setTimeout(() =>{
                            location.reload()

                          },50)
                        }
                      })

                    }
                  }else{
                    this.$message({
                      type: 'error',
                      message: res.message
                    })
                  }

                })
                // this.$message({
                //   type: 'success',
                //   message: res.data.message,
                //   onClose: ()=>{
                //     this.passwordForm.img_captcha = '' //  清空
                // }
              }else{
                this.$message({
                  type: 'error',
                  message: res.data.message
                })
              }
            }else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main{

  background: #fff;
  span{
    display: flex;
  }
  .el{
    border-top: 1px solid #f5f5f5;
    margin-top: 10px;
  }
  .Group-empty-list{
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    .Group-empty{

      text-align: left;
      color: #c1c1c1;
      padding: 20px 0;
      margin-top: 15px;
      .form_width{
        width: 480px;
        /* margin: 80px auto; */
      }
    }
  }
  .el-form{
    // margin-left: 120px;
  }
  .btn-wrap{
    margin-left: 180px;
  }
}
.el{
  padding: 10px 20px;
  margin-top: 0px !important;
}
</style>