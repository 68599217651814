// 用户相关api
import request from '../utils/request'
// import qs from 'qs'

//0010010-修改密码
export function passwordModify(data) {
  return request({
      method: 'POST',
      url: 'user/password/modify',
      data
      // data: qs.stringify(data)
  })
}

//0010011-忘记密码
export function passwordReset(data) {
  return request({
      method: 'POST',
      url: 'user/password/reset',
      data
      // data: qs.stringify(data)
  })
}

//0010012-修改绑定手机号
export function phoneModify(data) {
  return request({
      method: 'POST',
      url: 'user/phone/modify',
      data
      // data: qs.stringify(data)
  })
}